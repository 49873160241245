<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name_en"
                v-model="productForm.name_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name_ar"
                v-model="productForm.name_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="productForm.description_en"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="productForm.description_ar"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="product_gifts"
          >
            <b-form-group
              label="Campaign (id-name)"
              label-for="product_gifts"
            >
              <v-select
                v-model="productForm.product_gift_id"
                label="product_name"
                :options="product_gifts"
                :filterable="false"
                :reduce="product_gifts => product_gifts.id"
                @search="searchproduct_gifts"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
                <template
                  slot="selected-option"
                  slot-scope="option"
                >
                  {{ option.id }} - {{ option.name }}
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          md="6"
        >
          <b-form-group
            label="Status"
            label-for="Status"
          >
            <b-form-checkbox
              id="Status"
              v-model="productForm.status"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-media>

            <b-media-aside>
              <b-col
                cols="3"
              >

                <div>

                  <b-img
                    ref="previewEl"
                    v-img
                    :src="productForm.image"
                    class=" productImg"
                    height="200"
                    width="300"
                  />

                </div>
              </b-col>

              <!--/ image -->
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Arabic Image
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png,.jpeg,.gif"
                :hidden="true"
                plain
                @change="changeProfile($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="6">
          <b-media>

            <b-media-aside>
              <b-col
                cols="3"
              >

                <div>

                  <b-img
                    ref="previewEl"
                    v-img
                    :src="productForm.image_en"
                    class=" productImg"
                    height="200"
                    width="300"
                  />

                </div>
              </b-col>

              <!--/ image -->
            </b-media-aside>
            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectEnglishFile()"
              >
                Upload English Image
              </b-button>
              <b-form-file
                ref="refEngImg"
                accept=".jpg, .png,.jpeg,.gif"
                :hidden="true"
                plain
                @change="changeEngImg($event)"
              />
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addProduct()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addProduct()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      product_gifts: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const ad_image = ref('')
    // eslint-disable-next-line camelcase
    const ad_image_en = ref('')

    const productForm = ref({
      image: null,
      image_en: null,
    })
    return {
      getValidationState,
      productForm,
      ad_image,
      ad_image_en,
    }
  },
  mounted() {
    this.showProductAd()
    this.getproduct_gifts()
  },
  methods: {

    changeEngImg(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ad_image_en = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 3 / 1.98
        const belowStandard = 3 / 2.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.productForm.image_en = er.target.result
            }

            // eslint-disable-next-line prefer-destructuring
            this.ad_image_en = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    selectEnglishFile() {
      this.$refs.refEngImg.$el.click()
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ad_image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        const standard = 3 / 1.98
        const belowStandard = 3 / 2.05
        const userRatio = img.width / img.height
        if (userRatio <= standard && userRatio >= belowStandard) {
          if (input.files) {
            const reader = new FileReader()
            reader.onload = er => {
              this.productForm.image = er.target.result
            }
            // eslint-disable-next-line prefer-destructuring
            this.ad_image = input.files[0]
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image Aspect Ratio is Not Valid',
              icon: 'BellIcon',
              variant: 'danger',
              duration: 4000,

            },
          })
        }
      }
    },
    async searchproduct_gifts(searchQuery) {
      await axios.get(`admin/product_gifts?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    async getproduct_gifts() {
      await axios.get('admin/product_gifts').then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.products
        }
      })
    },
    showProductAd() {
      if (this.$route.params.id) {
        axios.get(`admin/product_ads/${this.$route.params.id}`).then(res => {
          this.productForm = res.data.data.product_ad
        })
      } else {
        return false
      }
      return true
    },
    addProduct() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        if (this.productForm.status === true) {
          this.productForm.status = 1
        } else {
          this.productForm.status = 0
        }

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          if (key !== 'image' || key !== 'image_en') {
            formData.append(key, this.productForm[key])
          }
        }
        formData.delete('image')
        formData.delete('image_en')
        if (this.ad_image) {
          // eslint-disable-next-line no-restricted-syntax
          formData.append('image', this.ad_image)
        }
        if (this.ad_image_en) {
          // eslint-disable-next-line no-restricted-syntax
          formData.append('image_en', this.ad_image_en)
        }
        axios.post(`admin/product_ads/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'product-ads' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()

        if (this.productForm.status === true) {
          this.productForm.status = 1
        } else {
          this.productForm.status = 0
        }

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          if (key !== 'image' || key !== 'image_en') {
            formData.append(key, this.productForm[key])
          }
        }
        formData.delete('image')
        formData.delete('image_en')
        if (this.ad_image) {
          // eslint-disable-next-line no-restricted-syntax
          formData.append('image', this.ad_image)
        }
        if (this.ad_image_en) {
          // eslint-disable-next-line no-restricted-syntax
          formData.append('image_en', this.ad_image_en)
        }
        axios.post('admin/product_ads', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'product-ads' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Somthing Went Wrong',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  </style>
